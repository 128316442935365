import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import { useApolloClient, gql } from '@apollo/client';

import { useFiberOrderContext } from 'components/fiber-2.0/Provider';
import { useAnalytics } from 'context/AnalyticsProvider';
import { AnchorLink } from 'components/Link';
import { CheckboxLabel, OptionGroup } from 'components/fiber-2.0/order/Styling';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import Spacing from 'layouts/Spacing';
import Box from 'components/forms/Box';
import Checkbox from 'components/forms/NewCheckbox';
import InputField from 'components/forms/InputField';
import Button from 'components/forms/Button';
import Error from 'components/error/Error';
import { PrivacyPolicyLink } from 'components/consent-portal/useSaveConsent';
import { trackBackEnd } from 'context/AnalyticsProvider';

const SEND_FIBER_LEAD = gql`
	mutation sendFiberLead(
		$navn: String!
		$telefon: String!
		$epost: String!
		$adresse: String
		$adresse_id: Float
		$bruksenhetsnummer: String
		$tilhorer_borettslag: Boolean
		$produkt: String
		$kontekst: String!
		$sokeord: String!
	) {
		success: sendFiberLead(
			navn: $navn
			telefon: $telefon
			epost: $epost
			adresse: $adresse
			adresse_id: $adresse_id
			bruksenhetsnummer: $bruksenhetsnummer
			tilhorer_borettslag: $tilhorer_borettslag
			produkt: $produkt
			kontekst: $kontekst
			sokeord: $sokeord
		)
	}
`;

//#region Styling
const FormTitle = styled.div`
	margin-bottom: 20px;
	text-align: left;
`;

const Row = styled.div`
	${p =>
		p.theme.media.medium(css`
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			> div {
				flex: 1;
				&:first-of-type {
					margin-right: 10px;
				}
				&:last-of-type {
					margin-left: 10px;
				}
			}
		`)}
`;

//#endregion

/**
 * Represents a component for the lead form for fiber inqueries.
 * @param {Object} props - The properties for the LeadForm component.
 * @param {string} [props.id='fiber-lead'] - The id of the lead form.
 * @param {string} [props.title='Det ser ikke ut som vi kan levere til deg akkurat nå ...'] - The title of the lead form.
 * @param {string} [props.text='.. men vi bygger stadig ut fiber til nye områder.'] - The text of the lead form.
 * @param {boolean} [props.showLinkToDevelopmentPage=true] - A boolean indicating whether to show a link to the fiber development page.
 * @param {string} [props.context=''] - The context of the lead form.
 * @param {boolean} [props.shadow=true] - A boolean indicating whether to show a shadow on the lead form.
 * @returns {ReactNode} - A React element representing the LeadForm component.
 **/
export default function LeadForm({
	id = 'fiber-lead',
	title = 'Det ser ikke ut som vi kan levere til deg akkurat nå ...',
	text = '.. men vi bygger stadig ut fiber til nye områder.',
	showLinkToDevelopmentPage = true,
	context = '',
	shadow = 'true',
	...props
}) {
	const {
		address,
		mainPageSlug,
		lead,
		setLead,
		setLeadSubmitted,
		chosenProduct,
		deliverResults,
	} = useFiberOrderContext();

	// If any of the following codes are present, change the title and text
	const mayBeAbleToDeliver = [
		'no-servicepoint-info',
		'zero-servicepoint-matches-found',
		'multiple-servicepoints-with-identical-dwelling',
		'zero-products',
	];
	if (mayBeAbleToDeliver?.includes(deliverResults?.code)) {
		title = 'Vi tror vi kanskje kan levere til deg ...';
		text = '.. du kan kontakte oss via skjemaet nedenfor.';
		showLinkToDevelopmentPage = false;
	}

	// If the context is 'typo-or-no-result', change the title and text
	if (context === 'typo-or-no-result') {
		title = 'Vi finner ikke adressen din, er det en skrivefeil?';
		text =
			'Hvis du er sikker på at adressen er riktig, kan du kontakte oss via skjemaet nedenfor.';
		showLinkToDevelopmentPage = false;
	}

	return (
		<Spacing
			id={id}
			data-cy="fiber-lead-form"
			spacing={{
				top: props?.spacing?.top || 'medium',
				bottom: props?.spacing?.bottom || 'none',
			}}>
			<TitleAndText title={title} nested={true}>
				{(text || showLinkToDevelopmentPage) && (
					<>
						{text}
						{showLinkToDevelopmentPage && (
							<>
								{' '}
								<AnchorLink
									href="/kundeservice/utbyggingsstatus"
									target="_blank">
									Her kan du se utbyggingsstatus på aktive
									prosjekter.
								</AnchorLink>
							</>
						)}
					</>
				)}
			</TitleAndText>

			<Box shadow={shadow} style={props?.style}>
				<Form
					id={id}
					formTitle={props?.formTitle}
					context={context}
					lead={{ ...lead, address, deliverResults, chosenProduct }}
					setLead={setLead}
					onSubmit={() => {
						setLeadSubmitted(true);
						navigate(
							`/${mainPageSlug}/bestill/takk-for-din-henvendelse`
						);
					}}
				/>
			</Box>
		</Spacing>
	);
}

export function Form({
	id,
	formTitle = 'Meld din interesse!',
	submitText = 'Meld interesse',
	context,
	lead,
	setLead,
	onSubmit,
}) {
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState('');

	const client = useApolloClient();
	const { identify } = useAnalytics();

	async function handleFormSubmit(e) {
		e.preventDefault();

		try {
			setSubmitting(true);
			setError('');

			const form = e?.target;

			const formData = {
				navn: `${form?.fornavn?.value} ${form?.etternavn?.value}`,
				epost: form?.epost?.value,
				telefon: form?.telefon?.value,
				adresse: lead?.address?.result || undefined,
				adresse_id: lead?.address?.id
					? parseInt(lead?.address?.id)
					: undefined,
				bruksenhetsnummer: lead?.address?.dwelling_unit || undefined,
				tilhorer_borettslag: lead?.tilhorer_borettslag || false,
				produkt: lead?.chosenProduct?.title || undefined,
				kontekst:
					context?.length > 0
						? context
						: lead?.deliverResults?.code || 'unknown',
				sokeord: lead?.deliverResults?.searchKeyword,
			};

			// If any of the required fields are missing, stop the function
			if (!formData?.navn || !formData?.epost || !formData?.telefon) {
				setSubmitting(false);
				setError('Mangler påkrevde felter.');
				return;
			}

			// Update state
			setLead({
				...formData,
				fornavn: form?.fornavn?.value,
				etternavn: form?.etternavn?.value,
			});

			const { data, error: leadError } = await client.mutate({
				mutation: SEND_FIBER_LEAD,
				variables: formData,
			});

			if (leadError || !data?.success) {
				setSubmitting(false);
				setError('Noe gikk galt under innsending');
				throw new Error(
					'sendFiberLead request failed: ',
					leadError?.message
				);
			}

			trackBackEnd('Lead Registered', {
				landing_page: lead?.deliverResults?.deliverable?.landing_page,
				traits: {
					name: formData?.navn,
					email: formData?.epost,
					phone: formData?.telefon,
					address: formData?.adresse,
					tilhorer_borettslag: formData?.tilhorer_borettslag,
				},
			});

			// Segment identify user
			identify({
				name: formData?.navn,
				email: formData?.epost,
				phone: formData?.telefon,
			});

			if (onSubmit) {
				onSubmit();
			}
		} catch (e) {
			console.log('Fiber lead form error', e);
			setSubmitting(false);
			setError('Noe gikk galt under innsending.');
			throw new Error('Fiber lead form error: ' + e?.message);
		}
	}

	return (
		<form
			id={`${id}-form`}
			data-cy={`${id}-form`}
			onSubmit={e => handleFormSubmit(e)}>
			{formTitle && (
				<FormTitle>
					<h3>{formTitle}</h3>
				</FormTitle>
			)}

			<Row>
				<InputField
					type="text"
					id="fornavn"
					name="fornavn"
					label="Fornavn"
					data-cy={`${id}-form-firstname`}
					defaultValue={lead?.fornavn || ''}
					required
				/>
				<InputField
					type="text"
					id="etternavn"
					name="etternavn"
					label="Etternavn"
					data-cy={`${id}-form-lastname`}
					defaultValue={lead?.etternavn || ''}
					required
				/>
			</Row>

			<Row>
				<InputField
					type="email"
					id="epost"
					name="epost"
					label="E-post"
					defaultValue={lead?.epost || ''}
					data-cy={`${id}-form-email`}
					required
				/>
				<InputField
					type="tel"
					id="telefon"
					name="telefon"
					label="Telefon"
					defaultValue={lead?.telefon || ''}
					data-cy={`${id}-form-phone`}
					required
				/>
			</Row>

			<OptionGroup
				className="checkboxes"
				style={{ marginBottom: '10px' }}>
				<li>
					<CheckboxLabel>Tilhører du et borettslag?</CheckboxLabel>
					<Checkbox
						type="checkbox"
						tabIndex="0"
						defaultChecked={lead?.isBRL || false}
						id="tilhorer_borettslag"
						name="tilhorer_borettslag"
						data-cy={`${id}-form-tilhorer_borettslag`}
						onChange={e => {
							let value = e.target.checked;
							if (e.target.checked) {
								e.target.setAttribute('checked', true);
							} else {
								e.target.removeAttribute('checked');
							}
							setLead({
								tilhorer_borettslag: value,
							});
						}}>
						Ja, jeg tilhører et borettslag
					</Checkbox>
				</li>
			</OptionGroup>

			<PrivacyPolicyLink style={{ marginBottom: '0' }} />

			{!submitting && error && (
				<Error
					style={{ marginTop: '30px', marginBottom: '10px' }}
					text={error}
					resolver="sendFiberLead"
					errorMessage={error || 'Send fiber lead failed'}
				/>
			)}

			<Button
				data-cy={`${id}-form-submit`}
				style={{ marginTop: '20px' }}
				type="submit"
				disabled={submitting}
				loading={submitting?.toString()}>
				{submitting ? 'Sender forespørsel' : submitText}
			</Button>
		</form>
	);
}
