import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useApolloClient, gql } from '@apollo/client';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import dayjs from 'dayjs';

import { useFiberOrderContext } from 'components/fiber-2.0/Provider';
import { scrollToElement } from 'libs/content';
import { GoBackToPreviousStep } from 'components/fiber-2.0/BaseComponents';
import TermsAndConditions from './fields/TermsAndConditions';
import Button from 'components/forms/Button';
import Loading from 'components/Loading';
import { BottomActions } from './Styling';
import { useAcceptedFields } from 'components/forms/useInputValidation';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import Error from 'components/error/Error';
import {
	trackGoogleAdsConversion,
	trackBackEnd,
} from 'context/AnalyticsProvider';
import LeadForm from '../LeadForm';

const ORDER_FIBER_DELIVERY = gql`
	mutation orderFiberDelivery(
		$fornavn: String!
		$etternavn: String!
		$telefon: String!
		$epost: String!
		$fodselsdato: String!
		$adresse: String!
		$tilhorer_borettslag: Boolean!
		$bruksenhetsnummer: String!
		$faktureringsadresse: String
		$produkt: String!
		$tilleggsprodukter: [AddOn]
		$oppstart: StartupInput!
		$godtatte_vilkaar: [VilkaarInput]!
		$leveringsdetaljer: DeliveryDetailsInput!
	) {
		orderFiberDelivery(
			fornavn: $fornavn
			etternavn: $etternavn
			telefon: $telefon
			epost: $epost
			fodselsdato: $fodselsdato
			adresse: $adresse
			tilhorer_borettslag: $tilhorer_borettslag
			bruksenhetsnummer: $bruksenhetsnummer
			faktureringsadresse: $faktureringsadresse
			produkt: $produkt
			tilleggsprodukter: $tilleggsprodukter
			oppstart: $oppstart
			godtatte_vilkaar: $godtatte_vilkaar
			leveringsdetaljer: $leveringsdetaljer
		) {
			id
			created_at
		}
	}
`;

/**
 * Represents a component for the second step in the fiber order form.
 * @param {Object} props - The properties for the Step2 component.
 * @returns {ReactNode} - A React element representing the Step2 component.
 **/
export default function Step2() {
	const [submitting, setSubmitting] = useState(false);

	const {
		chosenProduct,
		order,
		setOrder,
		setOrderSubmitted,
		chosenAddOns,
		addOns,
		mainPageSlug,
		loading,
		errors,
		setErrors,
		deliverResults,
		getTermsByView,
		termsLoadingRef,
	} = useFiberOrderContext();

	// Get the terms (from API)
	const terms = getTermsByView(deliverResults?.deliveryTerms, 'Bestilling');

	// Check if all required checkboxes are accepted
	const requiredCheckboxes = checkRequiredCheckboxes(terms, order);

	// Check if all required fields are accepted
	const readyToSubmit = useAcceptedFields(requiredCheckboxes);

	const client = useApolloClient();

	// Scroll to form
	useEffect(() => {
		if (order?.ordre_id) navigate(`/${mainPageSlug}/bestill/takk`);

		// If terms are not loaded, show error
		if (
			!termsLoadingRef?.current &&
			!deliverResults?.deliveryTerms?.length > 0
		) {
			console.error('No terms found for this product', deliverResults);
			setErrors([
				'Kunne ikke hente vilkår og betingelser for dette produktet',
			]);
		}

		const mainWrapper = document.getElementById('main');
		if (!mainWrapper) return;
		scrollToElement(mainWrapper);

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function onSubmit(e) {
		e.preventDefault();

		try {
			if (!order) {
				console.error('No order object, changing step to 1');
				navigate(`/${mainPageSlug}/bestill/steg1`);
			}

			if (!readyToSubmit) {
				console.error('Missing required fields');
				setErrors([
					'Du må godta vilkår og betingelser for å kunne bestille',
				]);
				return;
			}

			setSubmitting(true);

			const { data, error: orderError } = await client.mutate({
				mutation: ORDER_FIBER_DELIVERY,
				variables: order,
			});

			if (orderError || !data?.orderFiberDelivery?.id) {
				setErrors(['Noe gikk galt under bestillingen']);
				throw new Error(orderError?.message);
			}

			// Set order ID and creation date from response
			setOrder({
				ordre_id: data?.orderFiberDelivery?.id,
				opprettet: data?.orderFiberDelivery?.created_at,
			});

			let total = chosenProduct?.price12m || chosenProduct?.priceMonth;

			const products = [
				{
					product_id: chosenProduct?.api_id,
					name: chosenProduct.name,
					price: chosenProduct?.price12m || chosenProduct.priceMonth,
					quantity: 1,
					category: 'TV og Internett',
				},
			];

			order?.tilleggsprodukter?.forEach(p => {
				const addon = addOns?.find(a => a?.title === p?.tittel);

				total +=
					(addon?.priceMonth +
						addon?.establishmentFee -
						addon?.establishmentFeeDiscount) *
					p?.antall;

				products.push({
					product_id: addon?.slug,
					category: 'TV og Internett',
					name: p?.tittel,
					price: addon?.priceMonth || 0,
					quantity: p?.antall || 1,
				});
			});

			const address = order?.adresse?.split(', ');
			const street = address?.[0];
			const postalCode = address?.[1]?.split(' ')[0];
			const city = address?.[1]?.split(' ')?.slice(1)?.join(' ');

			trackBackEnd('Checkout Completed', {
				order_id: data?.orderFiberDelivery?.id,
				landing_page: deliverResults?.deliverable?.landing_page,
				total,
				currency: 'NOK',
				products,
				traits: {
					firstName: order.fornavn,
					lastName: order.etternavn,
					email: order.epost,
					phone: order.telefon,
					dob: dayjs(order.fodselsdato, 'DD/MM/YYYY').format(
						'YYYYMMDD'
					),
					address: {
						country: 'Norway',
						street,
						postalCode,
						city,
					},
				},
			});

			trackBackEnd(
				'identify',
				{
					traits: {
						order_id: data?.orderFiberDelivery?.id,
						landing_page: deliverResults?.deliverable?.landing_page,
						total,
						currency: 'NOK',
						products,
						firstName: order.fornavn,
						lastName: order.etternavn,
						email: order.epost,
						phone: order.telefon,
						dob: dayjs(order.fodselsdato, 'DD/MM/YYYY').format(
							'YYYYMMDD'
						),
						address: {
							country: 'Norway',
							street,
							postalCode,
							city,
						},
					},
				},
				'identify'
			);

			trackGoogleAdsConversion({
				send_to: 'AW-858476462/62-KCLiutLcZEK6frZkD',
				value: 3000,
				transaction_id: data?.orderFiberDelivery?.id,
				product_name: chosenProduct?.name,
			});

			if (!chosenAddOns?.length > 0 && addOns?.length > 0) {
				// Otherwise navigate to add-on-offer (before reciept page)
				navigate(`/${mainPageSlug}/bestill/en-siste-ting`);
			} else {
				// If already chosen add-on-product then navigate to reciept-page
				navigate(`/${mainPageSlug}/bestill/takk`);
			}

			setOrderSubmitted(true);
		} catch (error) {
			setSubmitting(false);
			console.log('Fiber order submit error', error);
			setErrors(['Noe gikk galt under bestillingen']);
		}
	}

	// If loading, show loading-component
	if (loading) {
		return <Loading text="Laster ..." style={{ paddingTop: '60px' }} />;
	}

	return (
		<form
			onSubmit={onSubmit}
			id="order-form-step-2"
			data-cy="fiber-order-form-step-2">
			<TitleAndText
				title="Da er du straks i mål med bestillingen!"
				nested={true}
			/>

			<TermsAndConditions view="Bestilling" showCheckboxes={true} />

			{errors?.length > 0 && (
				<Error
					text={`${errors?.[0]}.`}
					style={{ marginTop: '20px' }}
					resolver="orderFiberDelivery"
					errorMessage={
						errors?.[0] || 'Order fiber delivery step 2 failed'
					}
					popup={
						<LeadForm
							title="Kontakt oss"
							formTitle={null}
							context={`Feil med steg 2 i fiberbestilling: ${errors?.[0]}. Resolver: "orderFiberDelivery"`}
							text="Gi oss beskjed om feilen, så tar vi kontakt med deg."
							showLinkToDevelopmentPage={false}
							shadow={false}
							spacing={{ top: 'none', bottom: 'none' }}
							style={{ padding: '0' }}
						/>
					}
				/>
			)}

			<BottomActions>
				<Button
					type="submit"
					data-cy="fiber-order-form-submit"
					aria-label={
						'Klikk for å bekrefte bestillingen av ' +
						chosenProduct?.title
					}
					disabled={submitting || !readyToSubmit}
					loading={submitting?.toString()}
					icon={faCheck}>
					{submitting ? 'Sender bestilling' : 'Bekreft og bestill'}
				</Button>

				<GoBackToPreviousStep current={2} mainPageSlug={mainPageSlug} />
			</BottomActions>
		</form>
	);
}

/**
 * Generates a list of required checkboxes for the order form.
 * @param {Array} terms - The terms for the order form.
 * @param {Object} order - The order object.
 * @returns {Array} - An array of objects representing the required checkboxes.
 */
function checkRequiredCheckboxes(terms, order) {
	if (!terms || !order) return [];
	return terms?.reduce((acc, term) => {
		term?.paragraphs.forEach(p => {
			if (p?.content_type === 'Avkryssing_obligatorisk') {
				acc.push({
					id: p.text_id,
					value:
						!!order?.godtatte_vilkaar?.find(
							v => v?.id === p.text_id
						) || false,
				});
			}
		});
		return acc;
	}, []);
}
