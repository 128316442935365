import React from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';

import { priceFormatting, scrollToElement } from 'libs/content';
import Heading from 'libs/heading';
import MediumWidth from 'layouts/medium-width';
import MaxWidth from 'layouts/max-width';
import Spacing from 'layouts/Spacing';
import OrderSummary from './order/fields/OrderSummary';
import useWindow from 'context/useWindow';
import { GoBackButton } from './order/Styling';
import defaultImage from 'images/fiber/default-product.svg';
import defaultImageAddOn from 'images/fiber/access-point.png';
import SanitizeHtml from 'libs/SanitizeHtml';
import TitleAndText from 'parts/title-and-text/TitleAndText';

const Head = styled.div`
	margin-bottom: ${p => p.theme.spacing.desktop.small};
	text-align: ${p => p.alignment || 'left'};
	${p =>
		p.theme.media.large(css`
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			margin-bottom: ${p => p.theme.spacing.desktop.medium};
		`)}
`;

const Wrap = styled.div`
	text-align: ${p => p.alignment || 'left'};
	h2,
	h3,
	h4 {
		margin: 0 0 10px;
	}
	p {
		margin: 0;
	}
	${p =>
		p.alignment === 'center' &&
		css`
			ul {
				margin: 0px auto;
				width: fit-content;
			}
		`}
	.product__features {
		margin: 20px 0 0;
	}
`;

export const Price = styled.div`
	font-weight: 500;
	color: ${p => p.theme.colors.grey900};
	text-align: inherit;
	margin: 10px 0 0;

	span,
	em {
		font-size: 17px;
		line-height: 29px;
		font-style: normal;
	}

	&.add-on {
		font-weight: 400;
	}

	&.bold {
		margin: 0 0;
		span {
			font-weight: 600;
			font-size: 32px;
			line-height: 54px;
		}
		em {
			font-weight: 500;
			font-size: 17px;
			line-height: 46px;
		}
	}
`;

const Original = styled.div`
	opacity: 0.3;
	margin-right: 5px;
	display: inline;
	span {
		text-decoration: line-through;
	}
`;

const SmallText = styled.span`
	font-size: 17px !important;
	line-height: 29px !important;
	font-weight: 500 !important;
`;

/**
 * Represents a component intro.
 * @param {Object} props - The properties for the Intro component.
 * @param {string} props.alignment - The alignment of the intro.
 * @param {ReactNode} props.children - The children of the component.
 * @returns {ReactNode} - A React element representing the Intro component.
 */
export function Intro({ alignment = 'left', children, ...props }) {
	if (!props?.title && !props?.text && !children) return;

	return (
		<Head alignment={alignment}>
			{(props?.title || props?.text || props?.component) && (
				<TitleAndText {...props} />
			)}
			{children}
		</Head>
	);
}

/**
 * Represents add-on information.
 * @param {Object} props - The properties for the AddOnInfo component.
 * @param {string} props.title - The title of the addon.
 * @param {string} props.description - The description of the addon.
 * @param {number} props.price - The price of the addon.
 * @param {number} props.startUpCost - The startup cost of the addon.
 * @param {number} props.priceMonth - The monthly cost of the addon.
 * @param {string} props.headinglevel - The heading level of the addon.
 * @returns {ReactNode} - A React element representing the AddOnInfo component.
 */
export function AddOnInfo({
	title = '',
	description = '',
	price = 0,
	startUpCost = 0,
	priceMonth = 0,
	...props
}) {
	return (
		<Wrap {...props}>
			{title && (
				<Heading level={props?.headinglevel || 'h3'}>{title}</Heading>
			)}
			{description && <p className="addon__desc">{description}</p>}
			{price && (
				<Price className="addon__price">
					<Original>{formatPrice(price, 'kr/mnd')}</Original>
					{formatPrice(startUpCost, 'kr/etablering')}
					{', '}
					{formatPrice(priceMonth, 'kr/mnd')}.
				</Price>
			)}
		</Wrap>
	);
}

/**
 * Represents a slide up/down animation component.
 * @param {Object} props - The properties for the SlideUpDown component.
 * @param {boolean} props.visible - Whether the component should be visible.
 * @param {string} props.id - The id of the component.
 * @param {Object} props.variants - The variants for the component.
 * @param {ReactNode} props.children - The children of the component.
 * @returns {ReactNode} - A React element representing the SlideUpDown component.
 */
export function SlideUpDown({
	visible = false,
	id = '',
	variants = {
		open: {
			opacity: 1,
			height: 'auto',
			transition: {
				when: 'beforeChildren',
				duration: 0.25,
			},
		},
		collapsed: {
			opacity: 0,
			height: 0,
			transition: {
				when: 'beforeChildren',
				duration: 0.25,
				delay: 0.25,
			},
		},
	},
	children,
	...props
}) {
	return (
		<AnimatePresence initial={false}>
			{visible && (
				<motion.div
					key={id}
					id={id}
					initial="collapsed"
					animate="open"
					exit="collapsed"
					variants={variants}
					{...props}>
					<div>{children}</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
}

const Header = styled.div``;

const Main = styled.div`
	${p =>
		p.theme.media.large(css`
			flex-grow: 1;
			margin-right: 30px;
			max-width: calc(100% - 448px - 30px);
		`)}
`;

const SideBar = styled.div`
	margin: 0 0 0 30px;
	max-width: 100%;
	width: calc(448px - 30px);
	position: sticky;
	top: 140px;
	${p =>
		p.theme.media.mediumDown(css`
			display: none;
		`)}

	button {
		text-align: left;
	}
`;

const Wrapper = styled.div`
	${p =>
		p.theme.media.large(css`
			display: flex;
			align-items: ${p => (p.$centered && 'center') || 'flex-start'};
			justify-content: space-between;
		`)}
	${Main} {
		${p =>
			p.$centered &&
			css`
				margin: 0 auto;
				max-width: 100%;
			`}
	}
`;

const Footer = styled.div``;

/**
 * Represents a component for displaying an order form.
 * @param {Object} props - The properties for the OrderForm component.
 * @param {number} props.step - The step of the form.
 * @param {boolean} props.isBRL - Whether the component is for BRL.
 * @param {Object} props.spacing - The spacing of the component.
 * @param {ReactNode} props.header - The header of the component.
 * @param {boolean} props.centered - Whether the component should be centered.
 * @param {ReactNode} props.children - The children of the component.
 * @param {ReactNode} props.sideContent - The side content of the component.
 * @param {ReactNode} props.footer - The footer of the component.
 * @param {boolean} props.showSummaryMobile - Whether the summary should be shown on mobile.
 * @returns {ReactNode} - A React element representing the OrderForm component.
 */
export function OrderFormContent({
	step,
	isBRL = false,
	centered = false,
	header,
	children,
	sideContent,
	footer,
	showSummaryMobile = false,
	...props
}) {
	const { windowWidth } = useWindow();

	if (!children && !sideContent) return;

	const ContentWrapper = centered ? MediumWidth : MaxWidth;

	return (
		<ContentWrapper {...props}>
			<Spacing
				spacing={{
					top: 'small',
					bottom: props?.spacing?.bottom || 'xlarge',
				}}>
				{header && (
					<Header>
						<Spacing spacing={{ top: 'large', bottom: 'large' }}>
							{header}

							{showSummaryMobile && windowWidth <= 1099 && (
								<OrderSummary
									box={true}
									step={step}
									minimalized={true}
									className="bold"
								/>
							)}
						</Spacing>
					</Header>
				)}
				<Wrapper $centered={centered}>
					{children && <Main>{children}</Main>}
					{sideContent && !centered && (
						<SideBar>{sideContent}</SideBar>
					)}
				</Wrapper>
				{footer && <Footer>{footer}</Footer>}
			</Spacing>
		</ContentWrapper>
	);
}

/**
 * Format price with currency and unit.
 * @param {number} value - The value to format.
 * @param {string} unit - The unit to use.
 * @param {string} prefix - The prefix to use.
 * @returns {ReactNode} - A React element representing the formatted price.
 */
export function formatPrice(value, unit, prefix) {
	if (typeof value === 'undefined') return null;
	return (
		<>
			<span>
				{prefix && <>{prefix} </>}
				{priceFormatting(value, 'NOK', 'en-GB', false)}
			</span>
			{unit && <em>{` ${unit}`}</em>}
		</>
	);
}

/**
 * Represents a component for displaying a product price.
 * @param {Object} props - The properties for the ProductPrice component.
 * @param {number} props.priceMonth - The monthly price.
 * @param {number} props.price12m - The price for 12 months.
 * @param {number} props.establishmentFee - The establishment fee.
 * @param {number} props.establishmentFeeDiscount - The establishment fee discount.
 * @returns {ReactNode} - A React element representing the ProductPrice component.
 */
export function ProductPrice({
	priceMonth,
	price12m,
	establishmentFee,
	establishmentFeeDiscount,
	...props
}) {
	// If no price, no establishment fee and no establishment fee discount, return
	if (
		typeof priceMonth === 'undefined' &&
		typeof establishmentFee === 'undefined' &&
		typeof establishmentFeeDiscount === 'undefined'
	) {
		return;
	}

	// Determine class name
	let className = props?.className && `product__price ${props?.className}`;

	// Add category to class name
	if (props?.category) className += ` ${props?.category}`;

	// Add-on product: monthly price and establishment fee
	if (!!priceMonth && !!establishmentFeeDiscount && !!establishmentFee) {
		// Calculate discounted establishment fee
		const discountedFee = establishmentFee - establishmentFeeDiscount;

		return (
			<Price className={className}>
				<Original>{formatPrice(establishmentFee)}</Original>{' '}
				{formatPrice(discountedFee, 'kr/etablering')}
				{', '}
				{formatPrice(priceMonth, 'kr/mnd.')}
			</Price>
		);
	}

	// If price and discount price, show discounted price and original price
	if (
		!!priceMonth &&
		!!price12m &&
		typeof props?.discount12m !== undefined &&
		props?.discount12m !== 0
	) {
		className += ' campaign';
		return (
			<Price className={className}>
				<Original>{formatPrice(priceMonth)}</Original>{' '}
				{formatPrice(price12m)}
				<SmallText>kr/mnd i 12 mnd.</SmallText>
			</Price>
		);
	}

	// Default product price (show '+' before price if it's an upgrade (BRL-product))
	return (
		<Price className={className}>
			{formatPrice(
				priceMonth,
				'kr/mnd.',
				(props?.priceType === 'upgrade' && '+') || ''
			)}
		</Price>
	);
}

/**
 * Represents a component for displaying a button to go back to the previous step.
 * @param {Object} props - The properties for the GoBackToPreviousStep component.
 * @param {number} props.current - The current step.
 * @returns {ReactNode} - A React element representing the GoBackToPreviousStep component.
 */
export function GoBackToPreviousStep({ current, mainPageSlug }) {
	if (typeof current === 'undefined' || current === 1) return null;

	return (
		<GoBackButton
			onClick={e => {
				e.preventDefault();
				navigate(`/${mainPageSlug}/bestill/steg${current - 1}`);
				const mainWrapper = document.getElementById('main');
				if (!mainWrapper) return;
				scrollToElement(mainWrapper);
			}}
			title="Gå tilbake til forrige side">
			Tilbake til forrige side
		</GoBackButton>
	);
}

const MissingWrap = styled.div`
	width: 100%;
	max-width: 100%;
	img {
		width: auto;
		${p =>
			p.category === 'brl' &&
			css`
				width: ${p => (p.large && '300px') || '173px'};
			`}
		${p =>
			p.category === 'add-on' &&
			css`
				width: ${p => (p.large && '300px') || '120px'};
			`}
	}
`;

/**
 * Represents a component to display a placeholder for missing images with customizable text.
 *
 * @param {Object} props - The properties for the MissingImage component.
 * @param {string} props.category - The category of the missing image. (default|add-on|brl)
 * @param {string} props.alt - The alt text for the missing image.
 * @param {string} props.layout - The layout of the missing image.
 * @returns {ReactNode} - A React element representing the MissingImage component.
 */
export default function MissingImage({
	category = 'default',
	alt = '',
	layout = 'horizontal',
}) {
	return (
		<MissingWrap category={category} large={layout === 'vertical'}>
			<img
				src={
					(category === 'add-on' && defaultImageAddOn) || defaultImage
				}
				alt={alt}
			/>
		</MissingWrap>
	);
}

/**
 * Represents a component to display paragraphs.
 * @param {Object} props - The properties for the Paragraphs component.
 * @param {string} props.text - The text to display.
 * @returns {ReactNode} - A React element representing the Paragraphs component.
 */
export function Paragraphs({ text, ...props }) {
	if (!text) return null;

	const htmlRegex = /<[^>]*\s*>/g;
	htmlRegex.lastIndex = 0; // reset lastIndex
	const isHTML = htmlRegex.test(text);

	if (typeof text === 'string') {
		return isHTML ? (
			<SanitizeHtml html={text} {...props} type="span" className="term" />
		) : (
			<p {...props} className="term">
				{text}
			</p>
		);
	}

	return (
		<>
			{text.map((item, i) => {
				htmlRegex.lastIndex = 0; // reset lastIndex
				const isItemHTML = htmlRegex.test(item?.text);

				return isItemHTML ? (
					<SanitizeHtml
						key={i}
						html={item?.text}
						{...props}
						className="term"
						type="span"
					/>
				) : (
					<p key={i} {...props} className="term">
						{item?.text}
					</p>
				);
			})}
		</>
	);
}
